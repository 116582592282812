@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0b.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

#root {
  display: flex;
  flex-direction: column;
}

:root {
  --off-white: #fbf9f3;
  --off-black: #2b2a2a;
  --off-white-blue: #f8fdff;

  --grey: #a5a5a5;
  --grey-dark: #606060;
  --green: #60a3a6;
  --green-dark: #297f83;
  --blue: #6691bd;
  --blue-dark: #1e5a96;
  --purple: #a37eaa;
  --purple-dark: #744488;

  --main-bg-color: var(--off-white-blue);
  --main-text-color: var(--off-black);
  --theme-color: var(--blue);
  --theme-color-dark: var(--blue-dark);
}

html {
  font-size: 1rem;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-grid {
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;

  padding: 1.5rem 2.5rem;
  min-height: 100vh;
  width: 100%;

  @media (max-width: 960px) {
    padding: 0.5rem;
  }
}

.app-container,
.page-container {
  box-sizing: border-box;
  align-items: center;

  padding: 2rem;
  width: 100%;

  @media (max-width: 960px) {
    padding: 1rem;
  }
}

.app-container {
  max-width: 80rem;
  flex-direction: row;
}

.page-container {
  max-width: 50rem;
}

.watermelon {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

a {
  transition: all 0.1s ease;
}

.c0 {
  color: var(--grey, inherit);
  border-color: var(--grey, inherit);
  text-decoration: none;
}
a.c0:hover {
  color: var(--grey-dark);
  border-color: var(--grey-dark);
}

.c1 {
  color: var(--green, inherit);
  border-color: var(--green, inherit);
  text-decoration: none;
}
a.c1:hover {
  color: var(--green-dark);
  border-color: var(--green-dark);
}

.c2 {
  color: var(--blue, inherit);
  border-color: var(--blue, inherit);
  text-decoration: none;
}
a.c2:hover {
  color: var(--blue-dark);
  border-color: var(--blue-dark);
}

.c3 {
  color: var(--purple, inherit);
  border-color: var(--purple, inherit);
  text-decoration: none;
}
a.c3:hover {
  color: var(--purple-dark);
  border-color: var(--purple-dark);
}
